import { useQuery, useMutation, UseQueryOptions, QueryClient } from '@tanstack/react-query';
import companiesAPI, { ProjectToSendValues } from '@global-apis/companies';
import Project from '@global-interfaces/Project';
import { TypedDocumentNode, gql } from '@apollo/client';

import { graphqlClient } from '@global-apis/config';

interface ProjectValueData {
  project: Project;
}

interface ProjectValueVariables {
  id: number;
}

interface ParentProject {
  id: number;
  active: boolean;
  name: string;
  children: { id: number; name: string }[];
}

interface ParentProjectValueData {
  projects: ParentProject[];
}

interface ProjectsValueData {
  projects: Project[];
}

interface ProjectsValueVariables {
  sort_by: {
    field: string;
    order: string;
  };
  filters?: { active?: boolean; only_parent?: boolean };
}

export const GET_PROJECT_QUERY: TypedDocumentNode<ProjectValueData, ProjectValueVariables> = gql`
  query GetProject($id: Int) {
    project(id: $id) {
      id
      active
      address {
        address_1
        address_2
        id
        phone
        state
        zip_code
        city
        country
      }
      address_id
      billing_due_date
      billing_frequency
      budget_id
      expense_account
      external_id
      last_purchase_order_number
      name
      owner
      project_permissions {
        id
        is_reviewer
        project_id
        user_id
      }
      parent_id
      parent {
        name
      }
      sage_available
      sage_id
      tax_rate
      users
      procore_id
      procore_name
      project_settings
    }
  }
`;

export const GET_PROJECTS_QUERY: TypedDocumentNode<ProjectsValueData, ProjectsValueVariables> = gql`
  query GetProjects($sort_by: SortBy, $filters: CompaniesProjectFilters) {
    projects(sort_by: $sort_by, filters: $filters) {
      id
      active
      address {
        address_1
        address_2
        id
        phone
        state
        zip_code
        city
        country
      }
      address_id
      billing_due_date
      billing_frequency
      budget_id
      expense_account
      external_id
      last_purchase_order_number
      name
      owner
      project_permissions {
        id
        is_reviewer
        project_id
        user_id
      }
      parent_id
      parent {
        name
      }
      sage_available
      sage_id
      tax_rate
      users
      procore_id
      procore_name
      project_settings
    }
  }
`;

export const GET_PROJECTS_PARENT_QUERY: TypedDocumentNode<ParentProjectValueData, ProjectsValueVariables> = gql`
  query GetParentProjects($sort_by: SortBy, $filters: CompaniesProjectFilters) {
    projects(sort_by: $sort_by, filters: $filters) {
      id
      active
      name
      last_purchase_order_number
      children {
        id
        name
      }
    }
  }
`;

export const useProjectCreate = (options = {}) =>
  useMutation({
    mutationFn: (data: { name: string; company_id: number; users?: number[] }) => companiesAPI.project.create(data),
    ...options
  });

export const useProject = (id: number | null, options = {}) =>
  useQuery<Project | null, Error>({
    queryKey: ['project', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }

      try {
        const { data } = await graphqlClient.query({
          query: GET_PROJECT_QUERY,
          fetchPolicy: 'network-only',
          variables: { id }
        });

        return data.project;
      } catch (error) {
        // biome-ignore lint/suspicious/noConsole:
        console.error('Failed to fetch project:', error);
        throw error;
      }
    },
    ...options
  });

export const useProjectUpdate = (options = {}) =>
  useMutation({
    mutationFn: ({ id, data }: { id: number; data: ProjectToSendValues }) => companiesAPI.project.update(id, data),
    ...options
  });

export const useParentProjectList = (options = {}) =>
  useQuery<ParentProject[], Error>({
    queryKey: ['projects-only-active-parent'],
    queryFn: async () => {
      try {
        const { data } = await graphqlClient.query({
          query: GET_PROJECTS_PARENT_QUERY,
          fetchPolicy: 'network-only',
          variables: { sort_by: { field: 'name', order: 'asc' }, filters: { active: true, only_parent: true } }
        });

        return data.projects;
      } catch (error) {
        // biome-ignore lint/suspicious/noConsole:
        console.error('Failed to fetch parent projects:', error);
        throw error;
      }
    },
    ...options
  });

export const useActiveProjectList = (options: Omit<UseQueryOptions<Project[], Error>, 'queryKey' | 'queryFn'> = {}) =>
  useQuery<Project[], Error>({
    queryKey: ['projects-only-active'],
    queryFn: async () => {
      try {
        const { data } = await graphqlClient.query({
          query: GET_PROJECTS_QUERY,
          fetchPolicy: 'network-only',
          variables: { sort_by: { field: 'name', order: 'asc' }, filters: { active: true } }
        });

        return data.projects;
      } catch (error) {
        // biome-ignore lint/suspicious/noConsole:
        console.error('Failed to fetch active projects:', error);
        throw error;
      }
    },
    ...options
  });

export const useAllProjectList = (options: Omit<UseQueryOptions<Project[], Error>, 'queryKey' | 'queryFn'> = {}) =>
  useQuery<Project[], Error>({
    queryKey: ['projects-all'],
    queryFn: async () => {
      try {
        const { data } = await graphqlClient.query({
          query: GET_PROJECTS_QUERY,
          fetchPolicy: 'network-only',
          variables: { sort_by: { field: 'name', order: 'asc' } }
        });

        return data.projects;
      } catch (error) {
        // biome-ignore lint/suspicious/noConsole:
        console.error('Failed to fetch all projects:', error);
        throw error;
      }
    },
    ...options
  });

export const invalidateActiveProjectList = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: ['projects', 'projects-only-active'] });

export const invalidateAllProjectList = (queryClient: QueryClient) =>
  queryClient.invalidateQueries({ queryKey: ['projects', 'projects-all'] });

export const setProjectList = (queryClient: QueryClient, newData) =>
  queryClient.setQueryData(['projects-all'], newData);
