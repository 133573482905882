import qs from 'qs';

import api, { PaginatedResponse } from './config';

import Project from '@global-interfaces/Project';
import Invoice, { InboxInvoice, ProcoreTimeCard, QBPayrollTimeEntry } from '@global-interfaces/Invoice';
import { HistoryCollection } from '@global-interfaces/History';

export interface BudgetReport {
  document_url: string;
  project: Project;
  application_no: string;
  file_extension: string;
  date_created: string;
  origin: 'budget_export_excel' | 'draw_pay_app';
}

export interface BudgetReportResponse {
  message?: string;
  result?: BudgetReport[];
}

export interface ErpSyncResponse {
  Message: string;
  success: boolean;
  invoice_id?: number;
  line_items_missing?: boolean;
  user?: number;
}

export interface ErpAuthResponse {
  Message: string;
  success: boolean;
  url?: string;
  user?: number;
}

const invoiceAPI = {
  search: async (
    page: number,
    filters: {
      amount_total?: string | number;
      q?: string;
    }
  ) => {
    return api.get('search/invoices/', {
      params: {
        ...filters,
        page: page ? page : 1
      },
      paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    });
  },
  refreshFile: async (invoiceId: number) => api.post<void>(`/invoices/${invoiceId}/create-invoice-file/`),
  v2: {
    list: async (
      page: number,
      params: {
        q?: string | null;
        page?: number;
        page_size?: number;
        vendor_id?: number | number[];
        purchase_order_id?: number;
        project_id?: number | number[];
        billing_period_id?: number | number[];
        payment_method_id?: number | number[];
        docusign_status?: string | string[];
        erp_status?: string | string[];
        flow_status?: string | string[];
        procore_status?: string | string[];
        quickbooks_desktop_status?: string | string[];
        quickbooks_status?: string | string[];
        document_origin?: string[];
        active_or_null_projects?: boolean;
      }
    ) =>
      // can be Invoice[] or PaginatedResponse<Invoice>, depending on page_size. If page_size is zero, the backend will return all invoices without pagination
      api.get<PaginatedResponse<Invoice> | Invoice[]>('v2/invoices/', {
        params: { ...params, page: page ? page : 1 },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      })
  },
  v3: {
    inbox: async (
      page: number,
      params: {
        page_size: number;
        q: string;
        project_id: number[] | undefined;
        vendor_id: number[] | undefined;
        sort: string | null | undefined;
      }
    ) =>
      api.get<PaginatedResponse<InboxInvoice>>('v3/inbox/', {
        params: { ...params, page: page ? page : 1 },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      })
  },
  getLatest: async (params?: {
    project_id?: number | number[];
    vendor_id?: number | number[];
    status?: string | string[];
  }) =>
    api
      .get('inbox/ids/', {
        params: { ...params, limit: 1000 },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      })
      .then((res) => res.data),
  getInvoice: async (id: number) => api.get<Invoice>(`temp_invoices/${id}/`),
  inboxCount: async (params: { user: number; project_id?: number | number[]; vendor_id?: number | number[] }) =>
    api
      .get('/inbox/counts/', {
        params,
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      })
      .then((res) => res.data),
  inbox: async (
    page: number,
    params: {
      page_size: number;
      q: string;
      project_id: number[] | undefined;
      vendor_id: number[] | undefined;
      sort: string | undefined;
    }
  ) =>
    api.get('inbox/', {
      params: { ...params, page: page ? page : 1 },
      paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    }),
  latest: async () => api.get(`inbox/latest/`),
  update: async (id: number, data: Partial<Invoice>) => api.patch<Invoice>(`invoices/${id}/`, data),
  bulkUpdate: async (data: {
    ids: number[];
    data: {
      billing_period_id?: number | null | undefined;
      flow_status?: number | null;
      erp_status?: string;
      quickbooks_status?: string;
      quickbooks_desktop_status?: string;
      procore_status?: string;
    };
    function?: string;
  }) => api.patch<Invoice[]>('invoices/bulk-update/', data),
  bulkUpdateList: async (data: { id: number; order_po: number }[]) =>
    api.patch<Invoice[]>('invoices/bulk-update-list/', data),
  delete: async (id: number) => api.delete(`invoices/${id}/`),
  bulkDelete: async (ids: number[]) => api.delete('invoices/bulk-delete/', { data: { ids } }),
  sageSync: async (id: number) => api.post<ErpSyncResponse>(`invoices/${id}/sage-api-sync/`),
  quickBooksSync: async (id: number) => api.post(`invoices/${id}/quickbooks-api-sync/`),
  docuSignSync: async (id: number) => api.post(`invoices/${id}/docusign-signature-by-email/`),
  authQuickBooks: async (id?: number) =>
    api.get<ErpAuthResponse>('oauth/quickbooks/', {
      params: {
        project_id: id
      },
      paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    }),
  authDocuSign: async () => api.get('oauth/docusign/'),
  oAuthQuickBooks: async (url: string) => api.get(url),
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  getQuickbooksTime: async (params: any) =>
    api.get('quickbooks-time/search-invoices/', {
      params,
      paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    }),
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  getQuickBooksDesktopTime: async (params: any) =>
    api.get('quickbooks-desktop/get-time-tracking/', {
      params,
      paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    }),
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  getQuickBooksDesktopLastPayroll: async (params: any) =>
    api.get('quickbooks-desktop/last-payroll/', {
      params,
      paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    }),
  editQuickBooksDesktopLastPayroll: async (params: {
    results: QBPayrollTimeEntry[];
  }) => api.post('quickbooks-desktop/update-last-payroll/', params),
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  getProcoreTime: async (params: any) =>
    api.get('procore-timecard/search-invoices/', {
      params,
      paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    }),
  quickBooksDesktopSync: async (id: number, ignore_duplicates: boolean) =>
    api.post<ErpSyncResponse>(
      `invoices/${id}/quickbooks-desktop-api-sync/${ignore_duplicates ? '' : '?check_duplicates=true'}`
    ),
  quickBooksDesktopMacSync: async (data = {}) =>
    api.post(`quickbooks-desktop/gen-iif-files/`, { invoice_id_list: data }).then((response) => {
      const type = `${response.headers['content-type']};charset=UTF-8`;
      const blob = new Blob([response.data], { type: type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'inbuild_qb_desktop_mac_iif_file.iif';
      link.click();
      link.remove();
    }),
  quickBooksDesktopTimeSync: async () => api.post(`quickbooks-desktop/import-time-entries/`),
  quickBooksTimeSync: async (id: number) => api.post(`invoices/${id}/quickbooks-time-api-sync/`),
  authQuickBooksTime: async () => api.get('oauth/quickbooks-time/'),
  checkQuickbooksTime: async () => api.get('oauth/quickbooks/is-connected/'),
  checkConnectedDropBox: async () => api.get('oauth/dropbox/is-connected/'),
  checkConnectedGmail: async () =>
    api.get<{ message: string; success: boolean; email?: string }>('oauth/gmail/is-connected/'),
  checkConnectedOutlook: async () =>
    api.get<{ message: string; success: boolean; email?: string }>('oauth/outlook/is-connected/'),
  checkConnectedDocusign: async () => api.post('oauth/docusign/is-connected/'),
  authQuickBooksDesktop: async () =>
    api.get('quickbooks-desktop/auth/').then((response) => {
      const type = `${response.headers['content-type']};charset=UTF-8`;
      const blob = new Blob([response.data], { type: type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'inbuild_qb_desktop.qwc';
      link.click();
      link.remove();
    }),
  dropboxConnect: async () => api.get('oauth/dropbox/'),
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  dropboxUpdateRules: async (data: any) => api.post('dropbox/rules/', data),
  dropboxGetRules: async () => api.get('dropbox/rules/'),
  authProcore: async () => api.get<ErpAuthResponse>('oauth/procore/'),
  procoreSync: async (id: number) => api.post<ProcoreTimeCard[]>(`invoices/${id}/procore-api-sync/`),
  exportToPdf: async (data = {}) => api.post('accounting/budget-draw-pdf/', data),
  getPastBudgetDraws: async () => api.post<BudgetReportResponse>('accounting/past-budget-draws/'),
  historyLog: async (invoiceId: number) => api.get<HistoryCollection>(`history/invoices/${invoiceId}/`),
  // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
  exportPdf: async (data: any) => api.post(`invoice/stamp/`, data),
  sageIntacctExport: async (ids: number[]) =>
    api.post<Blob>(
      `sage-intacct/export/`,
      {
        invoice_ids: ids,
        task: true
      },
      {
        responseType: 'blob'
      }
    )
};

export default invoiceAPI;
