import { QueryClient, useQuery } from '@tanstack/react-query';
import { TypedDocumentNode, gql } from '@apollo/client';

import { graphqlClient } from '@global-apis/config';

interface GqlUser {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  groups: number[];
  is_active: boolean;
  project_permissions: {
    id: number;
    is_reviewer: boolean;
    is_viewer: boolean;
    billable_rate?: number;
    project_id: number;
    user_id: number;
  }[];
}

interface User extends GqlUser {
  is_admin: boolean;
  is_manager: boolean;
}

interface UserData {
  users: {
    results: GqlUser[];
  };
}

interface ActiveUserDataVariables {
  filters: {
    is_active: boolean;
  };
}

export const GET_USERS_QUERY: TypedDocumentNode<UserData> = gql`
  query GetUsersContextUsers {
    users {
      results {
        id
        username
        first_name
        last_name
        full_name
        groups
        email
        is_active
        project_permissions {
          id
          project_id
          user_id
          billable_rate
          is_reviewer
          is_viewer
        }
      }
    }
  }
`;

export const GET_ACTIVE_USERS_QUERY: TypedDocumentNode<UserData, ActiveUserDataVariables> = gql`
  query GetActiveUsersContextUsers($filters: AuthUsersFilters) {
    users(filters: $filters) {
      results {
        id
        username
        first_name
        last_name
        full_name
        groups
        email
        is_active
        project_permissions {
          id
          project_id
          user_id
          billable_rate
          is_reviewer
          is_viewer
        }
      }
    }
  }
`;

export const useActiveUserList = (options = {}) =>
  useQuery<User[], Error>({
    queryKey: ['users-active'],
    queryFn: async () => {
      try {
        const { data } = await graphqlClient.query({
          query: GET_ACTIVE_USERS_QUERY,
          fetchPolicy: 'network-only',
          variables: { filters: { is_active: true } }
        });

        return data.users.results.map((user) => ({
          ...user,
          is_admin: user.groups.includes(1),
          is_manager: user.groups.includes(2)
        }));
      } catch (error) {
        // biome-ignore lint/suspicious/noConsole:
        console.error('Failed to fetch active users:', error);
        throw error;
      }
    },
    ...options
  });

export const useUserList = (options = {}) =>
  useQuery<User[], Error>({
    queryKey: ['users'],
    queryFn: async () => {
      try {
        const { data } = await graphqlClient.query({ query: GET_USERS_QUERY, fetchPolicy: 'network-only' });

        return data.users.results.map((user) => ({
          ...user,
          is_admin: user.groups.includes(1),
          is_manager: user.groups.includes(2)
        }));
      } catch (error) {
        // biome-ignore lint/suspicious/noConsole:
        console.error('Failed to fetch users:', error);
        throw error;
      }
    },
    ...options
  });

// biome-ignore format: no break line
export const invalidateActiveUserList = (queryClient: QueryClient) => queryClient.invalidateQueries({ queryKey: ['users-active'] });

export const invalidateUserList = (queryClient: QueryClient) => queryClient.invalidateQueries({ queryKey: ['users'] });
