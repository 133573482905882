import jwtDecode from 'jwt-decode';

export function isJWTExpired(
  accessToken: string | null,
  bufferSeconds = 60
): {
  accessTokenIsExpired: boolean;
  accessTokenWillExpireWithinBufferTime: boolean;
} {
  if (!accessToken) {
    return {
      accessTokenIsExpired: true,
      accessTokenWillExpireWithinBufferTime: true
    };
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  const accessTokenWillExpireWithinBufferTime = decoded.exp - bufferSeconds < currentTime;

  return { accessTokenIsExpired: decoded.exp < currentTime, accessTokenWillExpireWithinBufferTime };
}

export function hasNoPermission(accessToken: string) {
  const decoded = jwtDecode(accessToken);

  return !decoded.groups?.includes('manager') && !decoded.groups?.includes('admin');
}
