import { useMemo, useRef, useState, useEffect } from 'react';
import { Grid, CircularProgress, Link as LinkMui, Stack } from '@mui/material';
import { Receipt as ReceiptIcon, Search as SearchIcon } from '@mui/icons-material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

interface PurchaseOrderData {
  id: number;
  order_number: string;
  project: {
    name: string;
  };
}
interface PurchaseOrdersData {
  purchaseOrders: { results: PurchaseOrderData[] };
}

export interface PurchaseOrdersVariables {
  limit: number;
  filters: {
    only_active_or_null_projects: boolean;
    q: string;
  };
  sort_by: {
    field: string;
    order: 'asc' | 'desc';
  };
}

interface Invoice {
  id: number;
  invoice_id: string | null;
  flow_status: string;
  vendor: {
    name: string;
  } | null;
  project: {
    name: string;
  } | null;
}

interface InvoiceData {
  invoices: {
    results: Invoice[];
  };
}

interface InvoiceVariables {
  limit: number;
  filters: {
    only_active_or_null_projects: boolean;
    q?: string;
  };
  sort_by: {
    field: string;
    order: 'asc' | 'desc';
  };
}

// TODO: get project name from the cache
const GET_SEARCH_PURCHASE_ORDERS: TypedDocumentNode<PurchaseOrdersData, PurchaseOrdersVariables> = gql`
  query GetPurchaseOrdersSearch($filters: AccountingPurchaseOrderFilters, $sort_by: SortBy, $limit: Int) {
    purchaseOrders(filters: $filters, sort_by: $sort_by, limit: $limit) {
      results {
        id
        order_number
        project {
          name
        }
      }
    }
  }
`;

// TODO: get vendor and project name from the cache
const GET_SEARCH_INVOICES: TypedDocumentNode<InvoiceData, InvoiceVariables> = gql`
  query GetInvoicesSearch($filters: InboxInvoicesFilters, $sort_by: SortBy, $limit: Int) {
    invoices(filters: $filters, sort_by: $sort_by, limit: $limit) {
      results {
        id
        invoice_id
        flow_status
        vendor {
          name
        }
        project {
          name
        }
      }
    }
  }
`;

const useDebounce = (value: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(id);
  }, [value, delay]);

  return debouncedValue;
};

const getNumber = (str: string) => {
  return str.replace('$', '').replaceAll(',', '');
};

const TopbarSearch = () => {
  const navigate = useNavigate();

  const [isShowing, setIsShowing] = useState(false);

  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isLoadingContract, setIsLoadingContract] = useState(false);

  const [isFetchedInvoice, setIsFetchedInvoice] = useState(false);
  const [isFetchedContract, setIsFetchedContract] = useState(false);

  const [invoiceResult, setInvoiceResult] = useState<
    { id: number; title: string; icon: JSX.Element; redirectTo: string }[]
  >([]);
  const [contractResult, setContractResult] = useState<
    { id: number; title: string; icon: JSX.Element; redirectTo: string }[]
  >([]);

  const [searchTerm, setSearchTerm] = useState('');

  const [fetchPurchaseOrders] = useLazyQuery(GET_SEARCH_PURCHASE_ORDERS, { fetchPolicy: 'network-only' });
  const [fetchInvoices] = useLazyQuery(GET_SEARCH_INVOICES, { fetchPolicy: 'network-only' });

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsShowing(true);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const noInvoiceInteraction = useMemo(
    () => !isFetchedInvoice && !isLoadingInvoice,
    [isFetchedInvoice, isLoadingInvoice]
  );

  const noContractInteraction = useMemo(
    () => !isFetchedContract && !isLoadingContract,
    [isFetchedContract, isLoadingContract]
  );

  const noResultInvoice = useMemo(
    () => isFetchedInvoice && invoiceResult.length === 0,
    [isFetchedInvoice, invoiceResult]
  );

  const noResultContract = useMemo(
    () => isFetchedContract && contractResult.length === 0,
    [isFetchedContract, contractResult]
  );

  const reset = () => {
    setInvoiceResult([]);
    setContractResult([]);
    setSearchTerm('');

    setIsLoadingContract(false);
    setIsLoadingInvoice(false);

    setIsFetchedContract(false);
    setIsFetchedInvoice(false);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleLinkClick = (item) => {
    setIsShowing(false);
    reset();
    navigate(item.redirectTo);
  };

  const onSearch = (q: string) => {
    const promises: Promise<void>[] = [];
    const newResult = [];

    setInvoiceResult([]);
    setContractResult([]);

    setIsLoadingContract(true);
    setIsLoadingInvoice(true);

    setIsFetchedContract(false);
    setIsFetchedInvoice(false);

    //to search by amount the user will have to type $, otherwise, we'll search by invoice id
    const isSearchingAmount = q.charAt(0) === '$';

    if (isSearchingAmount) {
      q = getNumber(q);
    }

    promises.push(
      fetchPurchaseOrders({
        variables: {
          filters: { only_active_or_null_projects: false, q },
          sort_by: { field: 'order_number', order: 'asc' },
          limit: 15
        }
      }).then((res) => {
        const foundData = res.data?.purchaseOrders?.results ?? [];

        const newData = [
          ...newResult,
          ...foundData.map((item, index) => {
            return {
              id: index,
              title: `${item.order_number} from project ${item.project.name}`,
              icon: <ReceiptIcon sx={{ fontSize: '20px' }} />,
              redirectTo: `/purchase-order/${item.id}`
            };
          })
        ];

        setContractResult(newData);
        setIsLoadingContract(false);
        setIsFetchedContract(true);
      })
    );

    promises.push(
      fetchInvoices({
        variables: {
          filters: { only_active_or_null_projects: false, q },
          sort_by: { field: 'invoice_id', order: 'asc' },
          limit: 15
        }
      }).then((res) => {
        const foundData = res.data?.invoices?.results ?? [];

        const newData = [
          ...newResult,
          ...foundData.map((item, index) => {
            const project = item.project ? item.project.name : 'UNASSIGNED PROJECT';
            const vendor = item.vendor ? item.vendor.name : 'UNASSIGNED VENDOR';
            return {
              id: index,
              title: `#${item.invoice_id ?? 'N/A'} from ${vendor} to ${project} Project (${item.flow_status})`,
              icon: <ReceiptIcon sx={{ fontSize: '20px' }} />,
              redirectTo: `/invoice/${item.id}`
            };
          })
        ];

        setInvoiceResult(newData);
        setIsLoadingInvoice(false);
        setIsFetchedInvoice(true);
      })
    );

    Promise.allSettled(promises).then(() => {
      if (!isShowing) {
        setIsShowing(true);
      }
    });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    if (debouncedSearchTerm) {
      onSearch(debouncedSearchTerm);
    } else {
      reset();
    }
  }, [debouncedSearchTerm]);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useHotkeys(
    'alt+s',
    (keyboardEvent) => {
      if (keyboardEvent.code === 'KeyS') {
        searchInputRef.current?.focus();
      }
    },
    // by default useHotKeys and input are triggered by pressing the keyboard key (keyDown), the “D key” is printed on the input right after it is focused. To solve this, the useHotKey will be triggered when unpressed the keyboard key and the “D key” will not be printed.
    {
      scopes: 'topbar-search',
      keydown: false,
      keyup: true
    }
  );

  return (
    <Grid
      display={{ mobile: 'none', tablet: 'flex' }}
      flexDirection="row"
      alignItems="center"
      data-testid="topbar-search">
      <Dropdown isOpen={isShowing} toggle={() => setIsShowing(!isShowing)} className="app-search">
        <DropdownToggle tag="a" style={{ display: 'none' }}></DropdownToggle>
        <form>
          <fieldset>
            <Grid display="flex" flexDirection="row" justifyContent="center" position="relative">
              <div className="input-group" id="top_bar_search_invoice-search">
                <input
                  id="topbar-search-input"
                  className="form-control dropdown-toggle"
                  data-testid="topbar-search-input"
                  placeholder="Search..."
                  value={searchTerm}
                  ref={searchInputRef}
                  onChange={handleChange}
                  onClick={handleButtonClick}
                />
                <SearchIcon sx={{ fontSize: '20px' }} className="search-icon" />

                <div className="input-group-append">
                  <button
                    className="btn btn-primary app-search__button"
                    style={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }}
                    onClick={handleButtonClick}>
                    Search
                  </button>
                </div>
              </div>

              <DropdownMenu className="dropdown-menu-animated topbar-dropdown-menu" data-testid="topbar-search-results">
                <div className="topbar-search__list">
                  <div className="topbar-search__list__group" data-testid="topbar-search-results-invoices">
                    <div
                      className="topbar-search__list__group__title"
                      data-testid="topbar-search-results-invoices-title">
                      Invoices
                    </div>

                    {isLoadingInvoice && (
                      <div className="topbar-search__list__group__loading">
                        <CircularProgress color="inherit" size={14} />
                      </div>
                    )}

                    {noInvoiceInteraction && (
                      <div
                        className="topbar-search__list__group__no-result"
                        data-testid="topbar-search-results-invoices-no-interaction">
                        Please enter a search query or amount value starting in $ to see results
                      </div>
                    )}

                    {noResultInvoice && (
                      <div
                        className="topbar-search__list__group__no-result"
                        data-testid="topbar-search-results-invoices-no-match">
                        There are no results that match your search
                      </div>
                    )}

                    {invoiceResult.map((item, i) => {
                      return (
                        <LinkMui
                          key={i}
                          color="#98a6ad"
                          underline="none"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleLinkClick(item)}
                          className="dropdown-item notify-item">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            {item.icon}
                            <span>{item.title}</span>
                          </Stack>
                        </LinkMui>
                      );
                    })}
                  </div>

                  <div className="topbar-search__list__group" data-testid="topbar-search-results-contracts">
                    <div
                      className="topbar-search__list__group__title"
                      data-testid="topbar-search-results-contracts-title">
                      Contracts
                    </div>

                    {isLoadingContract && (
                      <div className="topbar-search__list__group__loading">
                        <CircularProgress color="inherit" size={14} />
                      </div>
                    )}

                    {noContractInteraction && (
                      <div
                        className="topbar-search__list__group__no-result"
                        data-testid="topbar-search-results-contracts-no-interaction">
                        Please enter a search query or amount value starting in $ to see results
                      </div>
                    )}

                    {noResultContract && (
                      <div
                        className="topbar-search__list__group__no-result"
                        data-testid="topbar-search-results-contracts-no-match">
                        There are no results that match your search
                      </div>
                    )}

                    {contractResult.map((item, i) => {
                      return (
                        <LinkMui
                          key={i}
                          color="#98a6ad"
                          underline="none"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleLinkClick(item)}
                          className="dropdown-item notify-item">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            {item.icon}
                            <span>{item.title}</span>
                          </Stack>
                        </LinkMui>
                      );
                    })}
                  </div>
                </div>
              </DropdownMenu>
            </Grid>
          </fieldset>
        </form>
      </Dropdown>
    </Grid>
  );
};

export default TopbarSearch;
