import { useState, useMemo, MouseEvent } from 'react';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { Fullscreen, FullscreenExit } from 'react-bootstrap-icons';
import {
  Toolbar,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Box,
  Grid,
  ListItemIcon,
  Typography
} from '@mui/material';
import { Download, Keyboard, Help, PictureAsPdf, InsertDriveFile } from '@mui/icons-material';

import invoiceAPI, { BudgetReport } from '@global-apis/invoice';

import { SUPPORT_URL } from '@global-utils/defaultValues';

import { useAuth } from '@global-contexts/auth';
import ProfileMenu from '@global-components/ProfileMenu';
import TopBarSearch from '@global-components/TopbarSearch';
import AppsDropdown from '@global-components/AppsDropdown';
import Shortcut, { Item } from '@global-components/shortcut';
import { TimeSpanProps, TopbarProps } from '@global-components/Topbar/types';

import styles from '@global-assets/scss/config/_custom-variables.module.scss';
const { zIndexToast } = styles;

import './styles.scss';

const TimeSpan = ({ reportDate }: TimeSpanProps) => {
  const currentDate = useMemo(() => moment(), []);
  let foundTime = '';
  const historyEntireDate = moment(reportDate);
  const hours = currentDate.diff(historyEntireDate, 'hours');
  const minutes = currentDate.diff(historyEntireDate, 'minutes');

  if (hours === 0) {
    if (minutes === 0) {
      foundTime = 'Just now';
    } else {
      foundTime = `${minutes} minutes ago`;
    }
  } else {
    if (hours === 1) {
      foundTime = '1 hour ago';
    } else {
      foundTime = `${hours} hours ago`;
    }
  }
  return foundTime;
};

const Topbar = ({ handleFullscreen }: TopbarProps) => {
  const { userNavigator, user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);

  const [downloadedReportList, setDownloadedReportList] = useState<BudgetReport[]>([]);

  const [anchorDownloadedReportEl, setAnchorDownloadedReportEl] = useState<HTMLElement | null>(null);

  const openDownloadedRerpotsDialog = (event: MouseEvent<HTMLElement>) => {
    setAnchorDownloadedReportEl(event.currentTarget as HTMLElement);

    invoiceAPI.getPastBudgetDraws().then((response) => {
      setDownloadedReportList(response.data.result ?? []);

      setIsLoading(false);
    });
  };

  const closeDownloadedRerpotsDialog = () => {
    setAnchorDownloadedReportEl(null);
    setIsLoading(true);
  };

  const isDownloadedReportOpen = Boolean(anchorDownloadedReportEl);

  return (
    <Toolbar variant="dense" className="topbar" data-testid="topbar-wrapper">
      <Grid display="flex" flexDirection="row" alignItems="center">
        <Box display="none">
          <button
            // TODO: in the future we want to enable the fullscreen button at the app header.
            // We can't now because the reactstrap modal doesn't work in the fullscreen mode.
            // We need to use the @global-components/Modal but, this component doesn't allow the modal
            // floating at the right (the modal to show the pdf on inbox list)
            className="btn btn-no-shadow d-none"
            onClick={(event) => {
              event.preventDefault();

              if (handleFullscreen.active) {
                handleFullscreen.exit();
              } else {
                handleFullscreen.enter();
              }
            }}>
            {handleFullscreen.active ? <FullscreenExit /> : <Fullscreen />}
          </button>
        </Box>

        <TopBarSearch />
      </Grid>

      <Stack direction="row" spacing={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <>
            <IconButton
              aria-label="download-report"
              data-tooltip-id="topbar-download-past-reports"
              onClick={openDownloadedRerpotsDialog}>
              <Download />
            </IconButton>

            <Tooltip id="topbar-download-past-reports" variant="info" delayShow={400} style={{ zIndex: zIndexToast }}>
              <span>Recently Downloaded Reports</span>
            </Tooltip>

            <Menu
              disableScrollLock={true}
              anchorEl={anchorDownloadedReportEl}
              open={isDownloadedReportOpen}
              onClose={closeDownloadedRerpotsDialog}>
              <Stack gap={1} sx={{ minWidth: 100, maxWidth: 270 }}>
                {isLoading ? (
                  <Stack alignItems="center" height={24}>
                    <CircularProgress size={16} />
                  </Stack>
                ) : downloadedReportList.length > 0 ? (
                  downloadedReportList.map((downloadedReport) => (
                    <MenuItem
                      key={downloadedReport.document_url}
                      onClick={() => {
                        const link = document.createElement('a');
                        link.href = downloadedReport.document_url;
                        link.download = `${downloadedReport.project.name}_${downloadedReport.application_no}`;
                        link.click();
                      }}>
                      <Stack direction="row" spacing={0} alignItems="center" width="100%">
                        <ListItemIcon>
                          {downloadedReport.file_extension === 'pdf' ? (
                            <PictureAsPdf sx={{ fontSize: 26 }} />
                          ) : (
                            <InsertDriveFile sx={{ fontSize: 26 }} />
                          )}
                        </ListItemIcon>
                        <Stack direction="column" width="100%">
                          <Typography variant="inherit" noWrap>
                            {downloadedReport.project.name}
                          </Typography>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="body2" fontSize="12px" noWrap color="grey.400">
                              {downloadedReport.origin === 'budget_export_excel' && 'Budget'}
                              {downloadedReport.origin === 'draw_pay_app' &&
                                `Pay App ${downloadedReport.application_no}`}
                            </Typography>
                            <Typography variant="body2" fontSize="12px" noWrap color="grey.400">
                              <TimeSpan reportDate={downloadedReport.date_created} />
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </MenuItem>
                  ))
                ) : (
                  <Stack spacing={0} alignItems="center">
                    <Typography variant="body2" fontSize="14px" noWrap color="grey.700" padding="2px 10px">
                      No reports
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Menu>
          </>

          <>
            <IconButton
              aria-label="keyboard-shortcuts"
              data-tooltip-id="topbar-keyboard-shortcuts"
              // it calls useHotkeys(['meta+/', 'ctrl+/'], ...
              onClick={() => {
                if (userNavigator?.isMacOs === false) {
                  return document.dispatchEvent(
                    new KeyboardEvent('keydown', { code: 'Slash', key: '/', ctrlKey: true })
                  );
                } else {
                  return document.dispatchEvent(
                    new KeyboardEvent('keydown', { code: 'Slash', key: '/', metaKey: true })
                  );
                }
              }}
            >
              <Keyboard />
            </IconButton>

            <Shortcut targetElement="topbar-keyboard-shortcuts" place="bottom" title="Keyboard Shortcuts">
              <Item isMeta />
              <Item description="/" />
            </Shortcut>
          </>

          <>
            <IconButton
              aria-label="help"
              data-tooltip-id="topbar-help-link"
              onClick={() => window.open(SUPPORT_URL, '_blank')}>
              <Help />
            </IconButton>

            <Tooltip id="topbar-help-link" variant="dark" delayShow={400} style={{ zIndex: zIndexToast }}>
              <span>Help Center</span>
            </Tooltip>
          </>

          {user!.isAdmin && (
            <>
              <div data-tooltip-id="topbar-integrations">
                <AppsDropdown />
              </div>

              <Tooltip id="topbar-integrations" variant="dark" delayShow={400} style={{ zIndex: zIndexToast }}>
                <span>Integrations</span>
              </Tooltip>
            </>
          )}
        </Stack>

        <ProfileMenu />
      </Stack>
    </Toolbar>
  );
};

export default Topbar;
