import { useQuery } from '@tanstack/react-query';
import { TypedDocumentNode, gql } from '@apollo/client';

import { graphqlClient } from '@global-apis/config';

interface Tag {
  id: number;
  color: string;
  name: string;
  default: boolean;
}

interface TagData {
  tags: Tag[];
}

export const GET_TAGS_QUERY: TypedDocumentNode<TagData> = gql`
  query GetTagsContextTag {
    tags {
      id
      color
      name
      default
    }
  }
`;

export const useTagList = (options = {}) =>
  useQuery<Tag[], Error>({
    queryKey: ['tags'],
    queryFn: async () => {
      try {
        const { data } = await graphqlClient.query({ query: GET_TAGS_QUERY, fetchPolicy: 'network-only' });
        return data.tags;
      } catch (error) {
        // biome-ignore lint/suspicious/noConsole:
        console.error('Failed to fetch tags:', error);
        throw error;
      }
    },
    ...options
  });
