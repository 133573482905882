import { useContext, createContext, ReactNode, FC } from 'react';
import {
  ToastContainer,
  toast,
  ToastContent,
  ToastOptions,
  ToastItem,
  ToastPromiseParams,
  TypeOptions
} from 'react-toastify';
import uniqueId from 'lodash.uniqueid';

type ExtendedTypeOptions = TypeOptions | 'warn' | 'promise';

interface ExtendedToastOptions extends Omit<ToastOptions, 'type'> {
  clearPrevious?: boolean;
  type: ExtendedTypeOptions;
  promiseStates?: ToastPromiseParams;
}

const EXTEND_TYPE = {
  ...toast.TYPE,
  PROMISE: 'promise'
};

interface ToastContextType {
  toast: (content: ToastContent | Promise<unknown> | (() => Promise<unknown>), options: ExtendedToastOptions) => void;
  done: (toastId: string | number) => void;
  clear: (toastId?: string | number) => void;
  update: (toastId: string | number) => void;
  onChange: (callback: (toast: ToastItem) => void) => () => void;
}

export const ToastContext = createContext<ToastContextType>({} as ToastContextType);

const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const toastValue = (
    content: ToastContent | Promise<unknown> | (() => Promise<unknown>),
    options: ExtendedToastOptions
  ) => {
    const {
      closeButton = true,
      type = 'info',
      position = 'bottom-left',
      toastId = undefined,
      autoClose = 6000,
      className = undefined,
      hideProgressBar = true,
      closeOnClick = false,
      pauseOnHover = true,
      draggable = true,
      progress = undefined,
      clearPrevious = true,
      promiseStates,
      style = {}
    } = options;

    if (clearPrevious) {
      clear();
    }

    const foundToastId = toastId || uniqueId('toast-id');

    if (type === EXTEND_TYPE.PROMISE) {
      toast.promise(
        content as Promise<unknown>,
        {
          ...promiseStates
        },
        {
          toastId: foundToastId,
          closeButton,
          position,
          autoClose,
          className,
          hideProgressBar,
          closeOnClick,
          pauseOnHover,
          draggable,
          progress,
          theme: 'colored'
        }
      );
    } else {
      let toastFunc = toast.info;

      switch (type) {
        case 'error':
          toastFunc = toast.error;
          break;
        case 'info':
          toastFunc = toast.info;
          break;
        case 'success':
          toastFunc = toast.success;
          break;
        case 'warn':
        case 'warning':
          toastFunc = toast.warning;
          break;
        case 'default':
          toastFunc = toast.info;
          break;
        default:
          toastFunc = toast.info;
          break;
      }

      toastFunc(content as string | ReactNode, {
        toastId: foundToastId,
        closeButton,
        position,
        autoClose,
        className,
        hideProgressBar,
        closeOnClick,
        pauseOnHover,
        draggable,
        progress,
        theme: 'colored',
        style
      });
    }
  };

  const done = (toastId: number | string) => {
    toast.done(toastId);
  };

  const clear = (toastId?: number | string) => {
    toast.dismiss(toastId);
  };

  const update = (toastId: number | string) => {
    toast.update(toastId);
  };

  const onChange = (callback: (toast: ToastItem) => void) => {
    return toast.onChange(callback);
  };

  return (
    <ToastContext.Provider value={{ toast: toastValue, done, clear, update, onChange }}>
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

export default ToastProvider;
