import qs from 'qs';
import api, { PaginatedResponse, unauthenticatedApi } from '@global-apis/config';
import { UserGroup } from '@global-interfaces/User';
import Company from '@global-interfaces/Company';
import User from '@global-interfaces/User';

interface RegisterCompanyMinimalParams {
  company: {
    name: string;
  };
  user: {
    email: string;
    password: string;
  };
}
const authAPI = {
  login: async (username: string, password: string) =>
    api.post<{ access: string; refresh: string; mfa_enabled: boolean }>('auth/token/', {
      username,
      password
    }),
  logout: async () => api.post('auth/logout/'),
  refreshToken: async (code: string | null, refresh: string | null) =>
    api.post('auth/token/refresh/', { code, refresh }),
  registerCompany: async (data) =>
    unauthenticatedApi.post<{ company: Company; profile: User['profile']; user: User }>(
      'registration/signup/company/',
      data
    ),
  registerCompanyMinimal: async (data: RegisterCompanyMinimalParams) =>
    unauthenticatedApi.post<{ company: Company; user: User }>('registration/signup/company-minimal/', data),
  registerUser: async (data) => unauthenticatedApi.post('registration/signup/user/', data),
  resetPassword: async (data) => unauthenticatedApi.post('registration/reset-password/', data),
  forgotPassword: async (data) => unauthenticatedApi.post('registration/forgot-password/', data),
  inviteUser: async (data) => api.post('registration/invite-user/', data),
  listUsers: async (page?: number, params: { page_size?: number; groups?: 'admin' | 'manager' } = {}) =>
    api.get<PaginatedResponse<User>>('users/', {
      params: { ...params, page: page ? page : 1 },
      paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
    }),
  deactivateUser: async (id: number, username: string) =>
    api.patch<User>(`users/${id}/`, {
      username,
      is_active: false
    }),
  group: {
    list: async () =>
      api.get<PaginatedResponse<UserGroup>>('groups/', {
        params: { page_size: 1000 },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      })
  },
  mfa: {
    setup: async () => api.post<{ otp_auth_url: string; qr_code: string; user: number }>('auth/mfa/setup/'),
    enable: async (otp: string) => api.post('auth/mfa/enable/', { otp }),
    disable: async (otp: string, password: string) => api.post('auth/mfa/disable/', { otp, password }),
    validateOTP: async (otp: string, token: string) =>
      unauthenticatedApi.post(
        'auth/mfa/validate-otp/',
        { otp },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      )
  }
};

export default authAPI;
