const SUPPORT_URL = 'https://learn.inbuild.ai/';

//PDF
const PDF_ENDPOINTS = {
  INVOICE: 'invoices',
  PURCHASEORDER: 'purchase-orders',
  CHANGEORDER: 'change-orders',
  OWNERCHANGEORDER: 'owner/change-orders'
} as const;

const PDF_UPDATE_MODES = {
  UPDATE: 'update',
  NEW: 'new',
  CHANGE_ORDER_NEW: 'change-order-new',
  PURCHASE_ORDER: 'purchase-order',
  OWNER_CHANGE_ORDER_NEW: 'owner-change-order-new'
} as const;

//ERPS names
const SAGE = 'Sage';
const SAGE_INTACCT = 'Sage Intacct';
const PROCORE = 'Procore';
const QUICKBOOKS = 'QuickBooks Online';
const QUICKBOOKS_DESKTOP = 'QuickBooks Desktop';
const QUICKBOOKS_DESKTOP_MAC = 'QuickBooks Mac';
const PROCORE_TIMECARD = 'Procore Timecard';
//same as QUICKBOOKS
const QB = 'QuickBooks';
const QBD = 'QBD';
const QBDMAC = 'QBDMAC';
const QBDT = 'QBDT';
const QBT = 'QBT';
const DOCUSIGN = 'DocuSign';

//ERPS AVAILABLE / THIRD APPS
const SAGE_AVAILABLE = 'sage_available';
const SAGE_INTACCT_AVAILABLE = 'sage_intacct_available';
const PROCORE_AVAILABLE = 'procore_available';
const QUICKBOOKS_AVAILABLE = 'quickbooks_available';
const QUICKBOOKS_DESKTOP_AVAILABLE = 'quickbooks_desktop_available';
const QUICKBOOKS_DESKTOP_MAC_AVAILABLE = 'quickbooks_desktop_mac_available';
const QUICKBOOKS_DESKTOP_TIME_AVAILABLE = 'quickbooks_desktop_time_available';
const QUICKBOOKS_TIME_AVAILABLE = 'quickbooks_time_available';
const CLOCKSHARK_AVAILABLE = 'clockshark_available';
const PROCORE_TIMECARD_AVAILABLE = 'procore_timecard_available';
const DROPBOX_AVAILABLE = 'dropbox_available';
const DOCUSIGN_AVAILABLE = 'docusign_available';

//INTERNAL APPS
const PAYMENT_TRANSACTIONS = 'payment_transactions';

const ERPS_TO_CONNECT = [PROCORE, QB, QBD, DOCUSIGN];
const ERPS_CONNECT_BUTTON = [PROCORE, QB, QBD, QBT, PROCORE_TIMECARD, DOCUSIGN];
const ERPS_CONNECT_PROJECT_BUTTON = [QB];
const ERPS_CAN_DISABLE_CONNECT_BUTTON = [QBT, QBDT, PROCORE_TIMECARD];
const ERPS_CAN_DISABLE_CONNECT_BUTTON_BY_FEATURE_FLAG = [PROCORE];
const ERPS_EXPORT_BUTTON = [SAGE, PROCORE, QB, QBD, DOCUSIGN];
const ERPS_EXPORT_ALL_BUTTON = [QBDMAC, SAGE_INTACCT];
const ERPS_EXPORT_OPTIONS_BUTTON = [QBD];
const ERPS_SYNC_PAYMENT_BUTTON = [SAGE, PROCORE, QB, QBD];
const ERPS_SAVE_BUTTON = [SAGE];
const ERPS_IMPORT_VENDORS_BUTTON = [QBD, QB, PROCORE];
const ERPS_IMPORT_COST_CODES_BUTTON = [QBD, QB, SAGE, PROCORE];
const ERPS_IMPORT_PROJECTS_BUTTON = [QBD, QB, PROCORE];
const ERPS_IMPORT_PAYMENT_METHODS_BUTTON = [QBD, QB];
const ERPS_IMPORT_PAYMENT_BUTTON = [QBDMAC];
const ERPS_IMPORT_TIME_ENTRIES_BUTTON = [QBT, QBDT, PROCORE_TIMECARD];
const ERPS_IMPORT_TIME_ENTRIES_WITH_BILLINGPERIOD = [QBDT];
const ERPS_ENABLE_WEBSOCKET = [SAGE, PROCORE, QB, QBD, QBDT, DOCUSIGN];
const ERPS_WEBSOCKET_PURE_DATA = [QBDT];
const ERPS_SHOW_CUSTOM_FILTERS = [QBT, QBDT, PROCORE_TIMECARD];
const ERPS_SHOW_CUSTOM_FILTERS_LAST_SEARCH = [QBDT];
const ERPS_SHOW_CUSTOM_FILTERS_ADD_MANUALLY = [QBDT];
const ERPS_ENABLE_CUSTOM_PAGINATION = [QBT, QBDT];
const ERPS_SHOW_FILTERS = [QBT, QBDT, PROCORE_TIMECARD];
const ERPS_ENABLE_LOAD_ON_INIT = [SAGE, PROCORE, QB, QBD, QBDT, DOCUSIGN, QBDMAC];
const ERPS_SEND_TO_APP = [SAGE, PROCORE, QB, QBD];

const ERPS_READY_EXPORT_TAB = [SAGE, SAGE_INTACCT, PROCORE, QB, QBD, QBDMAC];
const ERPS_FAILED_TAB = [SAGE, PROCORE, QB, QBD];
const ERPS_DONE_TAB = [SAGE, SAGE_INTACCT, PROCORE, QB, QBD, QBDMAC];
const ERPS_READY_IMPORT_TAB = [QBT, QBDT, PROCORE_TIMECARD];
const ERPS_IMPORTED_TIME_ENTRIES_TAB = [QBT, QBDT, PROCORE_TIMECARD];
const ERPS_LIEN_WAIVERS_TAB = [DOCUSIGN];

const ERPS_DEFAULT_TAB = {
  [SAGE]: '1',
  [SAGE_INTACCT]: '1',
  [PROCORE]: '1',
  [QB]: '1',
  [QBD]: '1',
  [QBDMAC]: '1',
  [QBT]: '4',
  [QBDT]: '4',
  [PROCORE_TIMECARD]: '4',
  [DOCUSIGN]: '6'
};

//INVOICE DATA FIELDS
const ID = 'id';
const BILLING_PERIOD = 'billing_period';
const BILLING_PERIOD_ID = 'billing_period_id';
const INVOICE_ID = 'invoice_id';
const PURCHASE_ORDER_ID = 'purchase_order_id';
const DATE_ISSUE = 'date_issue';
const DUE_DATE = 'due_date';
const AMOUNT_TOTAL = 'amount_total';
const FLOW_STATUS = 'flow_status';
const NOTES = 'notes';
const VENDOR = 'vendor';
const VENDOR_ID = 'vendor_id';
const PROJECT = 'project';
const PROJECT_ID = 'project_id';
const QB_STATUS = 'quickbooks_status';
const DOCUSIGN_STATUS = 'docusign_status';
const SAGE_STATUS = 'erp_status';
const QB_DESKTOP_STATUS = 'quickbooks_desktop_status';
const QB_TIME_STATUS = 'document_origin';
const QB_DESKTOP_TIME_STATUS = 'document_origin';
const DROPBOX_STATUS = 'document_origin';
const PROCORE_TIMECARD_STATUS = 'document_origin';
const PROCORE_STATUS = 'procore_status';
const ORDER_ID = 'order_id';

//INVOICE STATUS
const UNASSIGNED = 'unassigned';
const APPROVED = 'approved';
const PENDING = 'pending';
const DISPUTED = 'disputed';
const PAID = 'paid';
const UNPAID = 'unpaid';
const CANCELLED = 'cancelled';
const NEW = 'new';
const UNDER_REVIEW = 'under_review';

// ERP STATUSES
const ERP_STATUS_NEW = 'new';
const ERP_STATUS_READY = 'ready';
const ERP_STATUS_DONE = 'done';
const ERP_STATUS_FAILURE = 'failed';
const ERP_STATUS_PROCESSING = 'processing';
const ERP_STATUS_NEED = 'need';
const ERP_STATUS_VOIDED = 'voided';

// flowStatus
const APPROVAL_FLOW_STATUS_LABELS = [
  { label: 'New', value: NEW },
  { label: 'Unassigned', value: UNASSIGNED },
  { label: 'Delete', value: CANCELLED },
  { label: 'Pending', value: PENDING },
  { label: 'Approved', value: APPROVED },
  { label: 'Disputed', value: DISPUTED },
  { label: 'Paid', value: PAID }
];

//@todo update status variables name to specify if erp status or ribbon status

//ERPS STATUS
const STATUS_NEW = 'new';
const STATUS_NONE = 'none';
const STATUS_DONE = 'done';

type NameIdType = typeof SAGE | typeof PROCORE | typeof QUICKBOOKS | typeof QUICKBOOKS_DESKTOP | typeof DOCUSIGN;

type GroupIdType =
  | 'quickbooks_statuses'
  | 'erp_statuses'
  | 'quickbooks_desktop_statuses'
  | 'docusign_statuses'
  | 'procore_statuses';

type AvailableIdType =
  | typeof QUICKBOOKS_AVAILABLE
  | typeof PROCORE_AVAILABLE
  | typeof QUICKBOOKS_DESKTOP_AVAILABLE
  | typeof QUICKBOOKS_DESKTOP_MAC_AVAILABLE
  | typeof QUICKBOOKS_DESKTOP_TIME_AVAILABLE
  | typeof QUICKBOOKS_TIME_AVAILABLE
  | typeof CLOCKSHARK_AVAILABLE
  | typeof PROCORE_TIMECARD_AVAILABLE
  | typeof DROPBOX_AVAILABLE
  | typeof DOCUSIGN_AVAILABLE
  | typeof SAGE_AVAILABLE;

type OptionIdType =
  | typeof ERP_STATUS_NEW
  | typeof ERP_STATUS_READY
  | typeof ERP_STATUS_DONE
  | typeof ERP_STATUS_FAILURE
  | typeof ERP_STATUS_PROCESSING
  | typeof ERP_STATUS_NEED
  | typeof ERP_STATUS_VOIDED;

type OptionsType = {
  name: 'New' | 'Ready' | 'Synced' | 'Failed' | 'Processing' | 'Sent For Signature' | 'Waiting to Send' | 'Voided';
  group: GroupIdType;
  id: OptionIdType;
};

export interface ErpStatusProps {
  name: NameIdType;
  id: NameIdType;
  groupId: GroupIdType;
  availableId: AvailableIdType;
  options: OptionsType[];
}

const ENTITY_STATUSES = [
  {
    name: 'Invoice',
    id: 'invoice',
    groupId: 'invoice_status',
    availableId: 'invoice_available',
    options: [
      { name: 'Unassigned', group: 'invoice_status', id: UNASSIGNED },
      { name: 'Approved', group: 'invoice_status', id: APPROVED },
      { name: 'Pending', group: 'invoice_status', id: PENDING },
      { name: 'Disputed', group: 'invoice_status', id: DISPUTED },
      { name: 'Paid', group: 'invoice_status', id: PAID },
      { name: 'Unpaid', group: 'invoice_status', id: UNPAID },
      { name: 'Cancelled', group: 'invoice_status', id: CANCELLED }
    ]
  },
  {
    name: 'Change Order',
    id: 'change_order',
    groupId: 'change_order_status',
    availableId: 'change_order_available',
    options: [
      { name: 'New', group: 'change_order_status', id: NEW },
      { name: 'Pending', group: 'change_order_status', id: PENDING },
      { name: 'Approved', group: 'change_order_status', id: APPROVED },
      { name: 'Disputed', group: 'change_order_status', id: DISPUTED },
      { name: 'Cancelled', group: 'change_order_status', id: CANCELLED }
    ]
  },
  {
    name: 'Contract',
    id: 'purchase_order',
    groupId: 'purchase_order_status',
    availableId: 'purchase_order_available',
    options: [
      { name: 'New', group: 'purchase_order_status', id: NEW },
      { name: 'Pending', group: 'purchase_order_status', id: PENDING },
      { name: 'Approved', group: 'purchase_order_status', id: APPROVED },
      { name: 'Disputed', group: 'purchase_order_status', id: DISPUTED },
      { name: 'Cancelled', group: 'purchase_order_status', id: CANCELLED }
    ]
  }
];

const ERPS_STATUSES: ErpStatusProps[] = [
  {
    name: QUICKBOOKS,
    id: QUICKBOOKS,
    groupId: 'quickbooks_statuses',
    availableId: QUICKBOOKS_AVAILABLE,
    options: [
      { name: 'New', group: 'quickbooks_statuses', id: ERP_STATUS_NEW },
      { name: 'Ready', group: 'quickbooks_statuses', id: ERP_STATUS_READY },
      { name: 'Synced', group: 'quickbooks_statuses', id: ERP_STATUS_DONE },
      { name: 'Failed', group: 'quickbooks_statuses', id: ERP_STATUS_FAILURE },
      { name: 'Processing', group: 'quickbooks_statuses', id: ERP_STATUS_PROCESSING }
    ]
  },
  {
    name: QUICKBOOKS_DESKTOP,
    id: QUICKBOOKS_DESKTOP,
    groupId: 'quickbooks_desktop_statuses',
    availableId: QUICKBOOKS_DESKTOP_AVAILABLE,
    options: [
      { name: 'New', group: 'quickbooks_desktop_statuses', id: ERP_STATUS_NEW },
      { name: 'Ready', group: 'quickbooks_desktop_statuses', id: ERP_STATUS_READY },
      { name: 'Synced', group: 'quickbooks_desktop_statuses', id: ERP_STATUS_DONE },
      { name: 'Failed', group: 'quickbooks_desktop_statuses', id: ERP_STATUS_FAILURE },
      { name: 'Processing', group: 'quickbooks_desktop_statuses', id: ERP_STATUS_PROCESSING }
    ]
  },
  {
    name: PROCORE,
    id: PROCORE,
    groupId: 'procore_statuses',
    availableId: PROCORE_AVAILABLE,
    options: [
      { name: 'New', group: 'procore_statuses', id: ERP_STATUS_NEW },
      { name: 'Ready', group: 'procore_statuses', id: ERP_STATUS_READY },
      { name: 'Synced', group: 'procore_statuses', id: ERP_STATUS_DONE },
      { name: 'Failed', group: 'procore_statuses', id: ERP_STATUS_FAILURE },
      { name: 'Processing', group: 'procore_statuses', id: ERP_STATUS_PROCESSING }
    ]
  },
  {
    name: SAGE,
    id: SAGE,
    groupId: 'erp_statuses',
    availableId: SAGE_AVAILABLE,
    options: [
      { name: 'New', group: 'erp_statuses', id: ERP_STATUS_NEW },
      { name: 'Ready', group: 'erp_statuses', id: ERP_STATUS_READY },
      { name: 'Synced', group: 'erp_statuses', id: ERP_STATUS_DONE },
      { name: 'Failed', group: 'erp_statuses', id: ERP_STATUS_FAILURE },
      { name: 'Processing', group: 'erp_statuses', id: ERP_STATUS_PROCESSING }
    ]
  },
  {
    name: DOCUSIGN,
    id: DOCUSIGN,
    groupId: 'docusign_statuses',
    availableId: DOCUSIGN_AVAILABLE,
    options: [
      { name: 'Sent For Signature', group: 'docusign_statuses', id: ERP_STATUS_NEED },
      { name: 'Waiting to Send', group: 'docusign_statuses', id: ERP_STATUS_READY },
      { name: 'Synced', group: 'docusign_statuses', id: ERP_STATUS_DONE },
      { name: 'Voided', group: 'docusign_statuses', id: ERP_STATUS_VOIDED }
    ]
  }
];

//RIBBON STATUS
const STATUS_IMPORT = undefined;
const STATUS_IMPORTED_ENTRIES_QBT = 'qbt';
const STATUS_IMPORTED_ENTRIES_QBDT = 'qbdt';
const STATUS_IMPORTED_ENTRIES_PROCORE_TIMECARD = 'procore_time';
const STATUS_SYNCED = 'synced';
const STATUS_PROCESSING = 'processing';
const STATUS_FAILED = 'failed';
const STATUS_READY = 'ready';
const STATUS_NEED_TO_SIGN = 'need';
const STATUS_VOIDED = 'voided';

const MAP_RIBBON_STATUS = {
  // @ts-expect-error - good to ignore
  [STATUS_IMPORT]: STATUS_IMPORT,
  [STATUS_DONE]: STATUS_SYNCED,
  [STATUS_PROCESSING]: STATUS_PROCESSING,
  [STATUS_FAILED]: STATUS_FAILED,
  [STATUS_READY]: STATUS_READY,
  [STATUS_NEED_TO_SIGN]: 'Need to Sign',
  [STATUS_VOIDED]: 'Voided',
  [STATUS_NEW]: STATUS_NEW
};

//RIBBON CLASSES
const RIBBON_SUCCESS = 'ribbon-success';
const RIBBON_WARNING = 'ribbon-warning';
const RIBBON_YC_WARNING = 'ribbon-yc-warning';
const RIBBON_DANGER = 'ribbon-danger';
const RIBBON_INFO = 'ribbon-info';
const RIBBON_DARK = 'ribbon-dark';

const MAP_RIBBON_ERP_STATUS_CLASSES = {
  [STATUS_DONE]: RIBBON_SUCCESS,
  [STATUS_PROCESSING]: RIBBON_WARNING,
  [STATUS_FAILED]: RIBBON_DANGER,
  [STATUS_READY]: RIBBON_INFO,
  [STATUS_NEED_TO_SIGN]: RIBBON_WARNING,
  [STATUS_VOIDED]: RIBBON_DARK,
  [STATUS_NEW]: RIBBON_DARK
};

const MAP_RIBBON_INVOICE_STATUS_CLASSES = {
  [APPROVED]: RIBBON_SUCCESS,
  [PENDING]: RIBBON_WARNING,
  [DISPUTED]: RIBBON_DANGER,
  [UNASSIGNED]: RIBBON_DARK,
  [PAID]: RIBBON_INFO,
  [NEW]: RIBBON_INFO,
  [CANCELLED]: RIBBON_DARK,
  [UNDER_REVIEW]: RIBBON_YC_WARNING
};

const MAP_RIBBON_CHANGE_ORDER_STATUS_CLASSES = {
  [NEW]: RIBBON_INFO,
  [PENDING]: RIBBON_WARNING,
  [APPROVED]: RIBBON_SUCCESS,
  [DISPUTED]: RIBBON_DANGER,
  [CANCELLED]: RIBBON_DARK
};

//SEARCH/LISTING/ORDER PARAMS
const ASC = 'asc';
const DESC = 'desc';

//PERMISSION TYPES
const MANAGER = 'manager';
const ADMIN = 'admin';

//ACTIVE STATUS
const ACTIVE = 'active';
const INACTIVE = 'inactive';

//COST TYPES
const CONTRACT_COST = 'Contract Cost';
const DIRECT_COST = 'Direct Cost';

//ACTIONS
const ACTION_APPROVE = 'approved';
const ACTION_DISPUTE = 'disputed';

//CHANGE ORDER STATUS OPTIONS
const CHANGE_ORDER_STATUS_OPTIONS = [
  {
    value: NEW,
    label: 'New'
  },
  {
    value: PENDING,
    label: 'Pending'
  },
  {
    value: APPROVED,
    label: 'Approved'
  },
  {
    value: DISPUTED,
    label: 'Disputed'
  },
  {
    value: CANCELLED,
    label: 'Cancelled'
  }
];

const CHANGE_ORDER_APPROVAL_DATE = 'approval_date';
const CHANGE_ORDER_STATUS = 'status';

const INVOICE_STATUS_BADGES = {
  [NEW]: 'primary',
  [UNASSIGNED]: 'secondary',
  [CANCELLED]: 'secondary',
  [PENDING]: 'warning',
  [APPROVED]: 'success',
  [DISPUTED]: 'danger',
  [PAID]: 'teal'
};

const CHANGEORDER_BADGES = {
  [NEW]: 'primary',
  [PENDING]: 'warning',
  [APPROVED]: 'success',
  [DISPUTED]: 'danger',
  [CANCELLED]: 'secondary'
};

const CREDITCARD_BLNC_FEE = 0.03;

const PASSWORD_REGEX =
  /^((?=.*[\d])(?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\d\s])|(?=.*[\d])(?=.*[A-Z])(?=.*[^\w\d\s])|(?=.*[\d])(?=.*[a-z])(?=.*[^\w\d\s])).{8,30}$/;

export {
  PDF_ENDPOINTS,
  PDF_UPDATE_MODES,
  SUPPORT_URL,
  SAGE,
  SAGE_INTACCT,
  PROCORE,
  QUICKBOOKS,
  QUICKBOOKS_DESKTOP,
  QUICKBOOKS_DESKTOP_MAC,
  QB,
  QBD,
  ERPS_TO_CONNECT,
  QBT,
  QBDT,
  QBDMAC,
  PROCORE_TIMECARD,
  DOCUSIGN,
  DOCUSIGN_STATUS,
  ERPS_CONNECT_BUTTON,
  ERPS_CONNECT_PROJECT_BUTTON,
  ERPS_CAN_DISABLE_CONNECT_BUTTON,
  ERPS_CAN_DISABLE_CONNECT_BUTTON_BY_FEATURE_FLAG,
  ERPS_SAVE_BUTTON,
  ERPS_EXPORT_BUTTON,
  ERPS_EXPORT_ALL_BUTTON,
  ERPS_EXPORT_OPTIONS_BUTTON,
  ERPS_SYNC_PAYMENT_BUTTON,
  ERPS_IMPORT_VENDORS_BUTTON,
  ERPS_IMPORT_COST_CODES_BUTTON,
  ERPS_IMPORT_PROJECTS_BUTTON,
  ERPS_IMPORT_PAYMENT_METHODS_BUTTON,
  ERPS_IMPORT_PAYMENT_BUTTON,
  ERPS_IMPORT_TIME_ENTRIES_BUTTON,
  ERPS_IMPORT_TIME_ENTRIES_WITH_BILLINGPERIOD,
  ERPS_ENABLE_WEBSOCKET,
  ERPS_WEBSOCKET_PURE_DATA,
  ERPS_SHOW_FILTERS,
  ERPS_SHOW_CUSTOM_FILTERS,
  ERPS_SHOW_CUSTOM_FILTERS_LAST_SEARCH,
  ERPS_SHOW_CUSTOM_FILTERS_ADD_MANUALLY,
  ERPS_ENABLE_CUSTOM_PAGINATION,
  ERPS_ENABLE_LOAD_ON_INIT,
  ERPS_SEND_TO_APP,
  ERPS_READY_IMPORT_TAB,
  ERPS_IMPORTED_TIME_ENTRIES_TAB,
  ERPS_LIEN_WAIVERS_TAB,
  ERPS_READY_EXPORT_TAB,
  ERPS_FAILED_TAB,
  ERPS_DONE_TAB,
  ERPS_DEFAULT_TAB,
  PROCORE_AVAILABLE,
  DROPBOX_AVAILABLE,
  QUICKBOOKS_AVAILABLE,
  QUICKBOOKS_DESKTOP_AVAILABLE,
  QUICKBOOKS_DESKTOP_MAC_AVAILABLE,
  QUICKBOOKS_DESKTOP_TIME_AVAILABLE,
  QUICKBOOKS_TIME_AVAILABLE,
  CLOCKSHARK_AVAILABLE,
  PROCORE_TIMECARD_AVAILABLE,
  SAGE_AVAILABLE,
  SAGE_INTACCT_AVAILABLE,
  DOCUSIGN_AVAILABLE,
  ERPS_STATUSES,
  PAYMENT_TRANSACTIONS,
  STATUS_IMPORT,
  STATUS_IMPORTED_ENTRIES_QBT,
  STATUS_IMPORTED_ENTRIES_QBDT,
  STATUS_IMPORTED_ENTRIES_PROCORE_TIMECARD,
  STATUS_NEW,
  STATUS_NONE,
  STATUS_DONE,
  STATUS_SYNCED,
  STATUS_PROCESSING,
  STATUS_FAILED,
  STATUS_READY,
  STATUS_NEED_TO_SIGN,
  STATUS_VOIDED,
  MAP_RIBBON_STATUS,
  MAP_RIBBON_ERP_STATUS_CLASSES,
  MAP_RIBBON_INVOICE_STATUS_CLASSES,
  MAP_RIBBON_CHANGE_ORDER_STATUS_CLASSES,
  ID,
  BILLING_PERIOD,
  BILLING_PERIOD_ID,
  INVOICE_ID,
  PURCHASE_ORDER_ID,
  DATE_ISSUE,
  DUE_DATE,
  AMOUNT_TOTAL,
  FLOW_STATUS,
  NOTES,
  VENDOR,
  VENDOR_ID,
  PROJECT,
  PROJECT_ID,
  QB_STATUS,
  SAGE_STATUS,
  QB_DESKTOP_STATUS,
  QB_DESKTOP_TIME_STATUS,
  QB_TIME_STATUS,
  DROPBOX_STATUS,
  PROCORE_TIMECARD_STATUS,
  PROCORE_STATUS,
  ORDER_ID,
  UNASSIGNED,
  APPROVED,
  PENDING,
  DISPUTED,
  PAID,
  CANCELLED,
  UNPAID,
  NEW,
  ASC,
  DESC,
  ADMIN,
  MANAGER,
  APPROVAL_FLOW_STATUS_LABELS,
  ACTIVE,
  INACTIVE,
  CONTRACT_COST,
  DIRECT_COST,
  ACTION_APPROVE,
  ACTION_DISPUTE,
  CHANGE_ORDER_STATUS_OPTIONS,
  INVOICE_STATUS_BADGES,
  CHANGEORDER_BADGES,
  CHANGE_ORDER_APPROVAL_DATE,
  CHANGE_ORDER_STATUS,
  CREDITCARD_BLNC_FEE,
  PASSWORD_REGEX,
  ENTITY_STATUSES
};
